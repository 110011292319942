import React from 'react';

import LoginWrapper from '~/components/wrapper/loginwrapper';
import LoginComponent from '~/components/logincomponent';

LoginPage.title = "Login"
export default function LoginPage() {

  return (
    <LoginWrapper>
      <LoginComponent />
    </LoginWrapper>
  );

}
