import React from 'react';

import { Card, Col, Image, Row, Typography } from 'antd';

import UnauthorizedPageWrapper from '~/components/wrapper/unauthorizedpagewrapper';
import UnauthorizedLeftSideView from '~/components/wrapper/unauthorizedleftsideview';
import UnauthorizedRightSideView from '~/components/wrapper/unauthorizedrightsideview';

const { Text, Title } = Typography;

export default function LoginWrapper(props) {

    return (
        <UnauthorizedPageWrapper>
            <Row justify="center">
                <Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12} align="center">
                    <UnauthorizedLeftSideView>
                        <Card bordered={false} style={{ background: 'transparent' }}>
                            <Image rel="prefetch" alt="" preview={false} src={process.env.NEXT_PUBLIC_STATIC_IMAGES_URL + "/empower.svg"} height="192px" width="192px" />
                            <Title level={2}>Empowering you to set up & run your own auctions</Title>
                            <Text>You no longer have to run an auction via a third party.
                                Our cutting edge technology puts the power in your hands,
                                allowing you to connect with your network of buyers and sellers. Get back in control.</Text>
                        </Card>
                    </UnauthorizedLeftSideView>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} align="center">
                    <UnauthorizedRightSideView>
                        {props.children}
                    </UnauthorizedRightSideView>
                </Col>
            </Row>
        </UnauthorizedPageWrapper>
    );

}